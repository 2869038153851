export const reCats = [
    {
      id: 0,
      contName: "Solar Energy",
      checked: true,
    },
    {
      
      id: 1,
      contName: "Biomass",
      checked: true,
    },
    {
      id: 2,
      contName: "Wind Energy",
      checked: true,
    },
    {
      id: 3,
      contName: "Hydropower",
      checked: true,
    },
]
